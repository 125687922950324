<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('exportTrophy.primary_sorting') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="3">
            <b-form-group
              label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}}
              </template>
              <b-form-select
                plain
                v-model="search.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group
                label-for="type"
              >
              <template v-slot:label>
                {{$t('globalTrans.type')}}
              </template>
              <b-form-select
                plain
                v-model="search.sector_type"
                :options="productServiceTypeList"
                id="type"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group
                label-for="product_service_sector_id"
              >
              <template v-slot:label>
                {{$t('externalUser.export_product_sector')}}
              </template>
              <v-select name="production_season_id"
                v-model="search.product_service_sector_id"
                label="text"
                :reduce="item => item.value"
                :options= productServiceSectorList
                :placeholder="$t('globalTrans.select')"
              />
              </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-button class="mt-20" size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('exportTrophy.primary_sorting') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction >
        <a href="javascript:" class="btn-add mr-2" v-if="showTopSheetButton" v-b-modal.modal-top-sheet> {{ $t('exportTrophy.top_sheet') }}</a>
        <a href="javascript:" class="btn-add" v-if="showSendBtn" v-b-modal.modal-remark> {{ $t('cip.sorting_send') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
              <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                <!-- A custom formatted header cell for field 'name' -->
                <template #head(check)="">
                  <span class="text-info text-center">
                    <b-form-checkbox
                      id="checkAll"
                      v-model="checkAll"
                      name="checkAll"
                    >
                    </b-form-checkbox>
                  </span>
                </template>
                <template v-slot:cell(check)="data">
                  <div class="text-center">
                    <b-form-checkbox
                      v-model="sector_ids"
                      name="sector_ids"
                      :value="data.item.product_service_sector_id"
                      :checked-value="data.item.product_service_sector_id"
                      :unchecked-value="null"
                    >
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>
                <template v-slot:cell(fiscal_year_id)="data">
                  {{ getCircularName(data.item.circular_id) }}
                </template>
                <template v-slot:cell(total_application) = "data">
                  {{ $n(data.item.total_application, { useGrouping: false }) }}
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.details variant=" action-btn status" size="sm" @click="showApplicant(data.item.product_service_sector_id)" class="action-btn status" :class="{ 'btn-primary': (data.item.total_application === data.item.total_primary_selected_application) }" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                </template>
              </b-table>
              </slot>
              <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-remark" size="md" :title="$t('globalTrans.remark')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Remark :circular_id="circularId" :current_status="4" :status="5" :menu_name_id="1" :key="circularId"/>
    </b-modal>
    <b-modal id="modal-details" size="xl" :title="$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-top-sheet" size="xl" :title="$t('exportTrophy.top_sheet')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title >
        <div class="flex-grow-1">
          {{ $t('exportTrophy.top_sheet') }}
          <b-button variant="success" @click="pdfExport" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
          </b-button>
        </div>
      </template>
      <TopSheet :fiscalYearId="search.fiscal_year_id" :sectorIds="sector_ids" :key="search.fiscal_year_id" ref="topSheet" />
    </b-modal>
  </div>
</template>
<script>
import Remark from '../Remark.vue'
import Details from '../Details.vue'
import TopSheet from './TopSheet.vue'
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { primarySortingListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Remark, Details, TopSheet
  },
  data () {
    return {
      showSendBtn: false,
      overallApplication: 0,
      circularId: 0,
      search: {
        fiscal_year_id: 0,
        sector_type: 0,
        product_service_sector_id: '',
        fiscal_year_name: '',
        limit: 20
      },
      productServiceSectorList: [],
      status: 0,
      noteTitle: '',
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'exportTrophyCircular.circular', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'externalUser.export_product_sector', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.total_application', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 6, thStyle: { width: '12%' } }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      },
      checkAll: false,
      sector_ids: []
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.sector_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.product_service_sector_id = ''
        this.productServiceSectorList = this.getProductServiceSectorList(newVal)
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.productServiceLocaleChange()
      }
    },
    checkAll: function (newVal) {
      if (newVal) {
        const sectorIdArr = []
        this.listData.forEach(item => {
          sectorIdArr.push(item.product_service_sector_id)
        })
        this.sector_ids = sectorIdArr
      } else {
        this.sector_ids = []
      }
    }
  },
  computed: {
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    productServiceTypeList () {
      return this.$store.state.ExportTrophyCipService.commonObj.productServiceTypeList.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    },
    columns () {
      // const labels = [
      //     { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
      //     { label: this.$t('externalUser.export_product_sector'), class: 'text-center' },
      //     { label: this.$t('globalTrans.total_application'), class: 'text-center' },
      //     { label: this.$t('globalTrans.action'), class: 'text-center' }
      //   ]
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'check' },
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'product_service_name_bn' },
          { key: 'total_application' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'check' },
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'product_service_name_en' },
          { key: 'total_application' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    currentLocale () {
      return this.$i18n.locale
    },
    showTopSheetButton () {
      let showButton = false
      if (this.listData.length && this.search.fiscal_year_name && this.sector_ids.length) {
        showButton = true
      }
      return showButton
    }
  },
  methods: {
    showApplicant (productServiceId) {
      this.$router.push({
        path: `/export-trophy-cip-service/export-trophy/primary-sorting-applicants?id=${productServiceId}`
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, primarySortingListApi, params)
      if (result.success) {
        this.$store.dispatch('setList', this.getRelationalData(result.data.data))
        this.paginationData(result.data, this.search.limit)
        // send button show when circular date is expired and all application has been approved or reject
        if (this.overallApplication === result.total_app) {
          this.showSendBtn = result.show_send_button
        } else {
          this.showSendBtn = false
        }
        this.circularId = result.data.data[0].circular_id
        this.search.fiscal_year_name = this.search.fiscal_year_id ? this.getFiscalYearName(this.search.fiscal_year_id) : ''
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
        this.showSendBtn = false
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      let total = 0
      const productServiceList = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList
      const listAll = data.map((item, index) => {
        total = total + item.total_application
        const productService = productServiceList.find(tmp => tmp.value === item.product_service_sector_id)

        const productServiceData = {
          serial: index,
          product_service_name_en: productService !== 'undefined' ? productService.text_en : '',
          product_service_name_bn: productService !== 'undefined' ? productService.text_bn : ''
        }
        return Object.assign({}, item, productServiceData)
      })
      this.overallApplication = total
      return listAll
    },
    getProductServiceSectorList (sectorType) {
      return this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.filter(sector => sector.type === sectorType && sector.assign_for === 2)
    },
    productServiceLocaleChange () {
      this.productServiceSectorList = this.productServiceSectorList.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    },
    getCircularName (id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.circularList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    pdfExport () {
      this.$refs.topSheet.pdfExport()
    }
  }
}
</script>
<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
