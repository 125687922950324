import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      const currentLocale = vm.currentLocale
      const fiscalYearId = vm.fiscalYearId
      // const item = vm.item
      const itemDetails = vm.itemDetails
      let listItemOne, listItemTwo
      if (currentLocale === 'en') {
        listItemOne = 'The National Export Trophy will be awarded at the state level in recognition of the contribution of individuals, institutions and organizations who have contributed significantly to the growth of Bangladesh\'s exports. In this context, the list of trophy recipient exporters has been prepared for the fiscal year ' + vm.getFiscalYearName(fiscalYearId) + ' based on the National Export Trophy Policy-2022. According to the policy, the decisions taken in the meeting are as follows:'
        listItemTwo = 'A list of organizations should be prepared for selection of National Export Trophy recipients on the basis of minimum export income in ' + vm.$n(vm.itemDetails.length) + ' product and service sectors. Sectors with minimum revenue (in millions of US dollars) are as follows:'
      } else {
        listItemOne = 'বাংলাদেশের রপ্তানি বৃদ্ধিতে যে সকল ব্যক্তি, প্রতিষ্ঠান এবং সংস্থা উল্লেখযোগ্য অবদান রেখেছে তাদের অবদানের স্বীকৃতিস্বরূপ রাষ্ট্রীয় পর্যায়ে পুরুস্কারে ভূষিত করার লক্ষ্যে জাতীয় রপ্তানি ট্রফি প্রদান করা হবে। এ প্রেক্ষিতে জাতীয় রপ্তানি ট্রফি নীতিমালা -২০২২ এর ভিত্তিতে ' + vm.getFiscalYearName(fiscalYearId) + ' অর্থবছরের জন্য ট্রফি প্রাপক রপ্তানিকারক প্রতিষ্ঠান এর তালিকা তৈরী করা হয়েছে। উক্ত নীতিমালা অনুযায়ী সভার গৃহীত সিদ্ধান্তসমূহ নিম্নরুপঃ'
        listItemTwo = vm.$n(vm.itemDetails.length) + ' টি পণ্য ও সেবা খাতে নূন্যতম রপ্তানি আয়ের ভিত্তিতে জাতীয় রপ্তানি ট্রফি প্রাপক নির্বাচনের লক্ষ্যে প্রতিষ্ঠানের তালিকা তৈরী করতে হবে। নূন্যতম  আয়সহ (মিলিয়ন মার্কিন ডলারে) খাতসমূহ নিম্নরূপ:'
      }
      const listItems = {
        ul: [
          { text: listItemOne, alignment: 'left', style: 'td' },
          { text: listItemTwo, alignment: 'left', style: 'td' }
        ]
      }
      pdfContent.push(listItems)
      pdfContent.push({ text: '', style: 'fertilizer' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophyConfig.product_and_service_sector'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophyConfig.minimum_income_usd'), style: 'th', alignment: 'center', bold: true }
        ]
      ]
      if (itemDetails.length) {
        itemDetails.forEach((detailsItem, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: vm.getSectorName(detailsItem.product_service_sector_id), alignment: 'center', style: 'td' },
            { text: vm.$n(detailsItem.minimum_income), alignment: 'center', style: 'td' }
          ])
        })
      } else {
        allRows.push([
          { text: vm.$t('globalTrans.noDataFound'), alignment: 'center', style: 'td', colSpan: 3 },
          { },
          { }
        ])
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['10%', '60%', '30%'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'portrait',
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 11,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
        header2: {
          fontSize: 11,
          margin: [0, 10, 0, 0]
        },
        header3: {
          fontSize: 10,
          margin: [0, 6, 0, 0]
        },
        header4: {
          fontSize: 9,
          margin: [0, 6, 0, 9]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 11]
        },
        krishi: {
          margin: [0, -5, 0, 11],
          alignment: 'center'
        },
        tableSubHead: {
          margin: [0, 5, 0, 11]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
